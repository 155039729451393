import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9c09ff0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchLocale($event)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale) => {
      return (_openBlock(), _createElementBlock("option", {
        key: locale,
        value: locale,
        selected: locale == _ctx.$i18n.locale
      }, _toDisplayString(_ctx.uppercaseLocale(locale)), 9, _hoisted_1))
    }), 128))
  ], 32))
}