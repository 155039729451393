import { defineComponent } from "vue";

export default defineComponent({
  name: "LocaleSwitcher",
  data() {
    return {
      locales: process.env.VUE_APP_MULTILINGUAL_SUPPORTED_LOCALE.split(",")
    };
  },
  methods: {
    uppercaseLocale(locale: string) {
      return locale.toUpperCase();
    },
    switchLocale(event: Event) {
      const element = event.target as HTMLInputElement;
      const locale = element.value;

      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        
        const to = this.$router.resolve({ params: { locale } });
        this.$router.push({
            name:to.name as string,
            params:to.params
        });
      }
    },
  },
});
